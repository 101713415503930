
import { Component, Vue } from "vue-property-decorator";
import { api } from "@/api";
import { commitAddNotification } from "@/store/main/mutations";
import { readToken } from "@/store/main/getters";
import { readDataset, readDatasets } from "@/store/dataset/getters";
@Component({
  components: {},
})
export default class ConversationView extends Vue {
  public loading: boolean = false;
  public modelPredictions: any[] = [];
  public failed: boolean = true;
  
  public conversationSummary: string = "";
  public conversationSummaryError: any = null;
  public loadingChat: boolean = false;
  public conversationId: string = "";
  public chatConversation: any = []; //[{"role": "assistant", "content": "hejsan"}, {"role": "user", "content": "hej på dig med"}];


  public async mounted() {
    if (this.$route.query.conversation_id !== undefined) {
      this.conversationId = this.$route.query.conversation_id as string;
    }
    this.getConversationSummary(true);
  }

  public openNewWindow() {
    window.open(
          `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${
            parseInt(this.$router.currentRoute.params.id, 10)
          }/dashboard/browse?conversation_id=${this.conversationId}`,
          "_blank",
        );
  }

  get token() {
    return readToken(this.$store);
  }
  get dataset() {
    return readDataset(this.$store)(+parseInt(this.$router.currentRoute.params.id, 10));
  }

  public getConversationSummary(generateNew: boolean = false) {
    if (generateNew) {
      // if we generate a new summary, we need to clear the old one
      this.conversationSummary = "";
      this.chatConversation = [];
    }
    console.log("dataset", this.dataset);
    this.loadingChat = true;
    api
      .streamConversationResponse(
        this.token,
        this.dataset === undefined ? null : this.dataset.summary_model,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        this.conversationId,
        this.chatConversation,
        generateNew,
      )
      .then((reader) => {
        let decoder = new TextDecoder();

        const processText = (result: ReadableStreamReadResult<Uint8Array>) => {
          if (result.done) {
            console.log("Stream complete");
            this.chatConversation.push({
              role: "assistant",
              content: `\n In addition to the given conversation, here is a summary of it: ${this.conversationSummary}`,
            });
            // send request to save the summary
            api.saveConversationSummary(
              this.token,
              parseInt(this.$router.currentRoute.params.workspaceid, 10),
              parseInt(this.$router.currentRoute.params.id, 10),
              this.conversationId,
              this.conversationSummary,
            );
            return;
          }

          console.log("RESPONSE", decoder.decode(result.value));
          this.conversationSummary += decoder.decode(result.value); // append response string chunk

          // Read the next chunk
          reader
            .read()
            .then(processText)
            .catch((error) => {
              console.log("error when getting conversation summary", error);
              this.conversationSummaryError = error;
            });
        };

        // Start reading the stream
        reader
          .read()
          .then(processText)
          .catch((error) => {
            console.log("error when getting conversation summary", error);
            this.conversationSummaryError = error;
          });
        this.loadingChat = false;
      })
      .catch((error) => {
        console.log("error when getting conversation summary", error);
        this.conversationSummaryError = error;
        this.loadingChat = false;
      });
  }
}   
